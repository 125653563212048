import React, { useState, useEffect } from "react"
import _ from "lodash";
import loadable from "@loadable/component"
import { graphql } from "gatsby";
import LayoutTwo from "../components/layoutTwo"
import Seo from '../components/seo';
import sitelogoimage from "../images/logo.svg";

import { PageLinks } from "../common/site/page-static-links";
import FloatingButton from "../components/FloatingButton/FloatingButton";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"))
const PropertyDetailBanner = loadable(() => import("../components/PropertyDetailBanner/PropertyDetailBanner"))
const PropertyDetailDesc = loadable(() => import("../components/PropertyDetailDesc/PropertyDetailDesc"))
const SimilarProperties = loadable(() => import("../components/SimilarProperties/SimilarProperties"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const PropertyDetails = ({ data, children }) => {
    const [negoEmail, setNegoEmail] = useState('')
    const [officedetailData, setofficeDetailData] = useState([])

    const property = data?.strapiProperty
    const officeData = data?.allStrapiOffice?.edges
    // const companyphone = data?.site?.siteMetadata?.mailVars?.company_phone
    const officeDetailGlobal = data?.strapiOfficeDetail
    const page_url = typeof window !== 'undefined' ? window.location.href : ''

    const soldArray = ["Sold"]
    let isSoldProperty = soldArray.includes(property?.status)

    let processedImages = JSON.stringify({});
    if (property.imagetransforms?.images_Transforms) {
        processedImages = property?.imagetransforms?.images_Transforms;
    }

    let propertyDetailImg = [];
    for (let i = 0; i < property?.images?.strapi_json_value.length; i++) {
        if (property?.images?.strapi_json_value[i].url) {
            propertyDetailImg.push(property?.images?.strapi_json_value[i].url);
        }
        else if (property?.images?.strapi_json_value[i].srcUrl) {
            propertyDetailImg.push(property?.images?.strapi_json_value[i].srcUrl);
        }
    }

    // epc lightbox
    let propertyEpcImg = [];
    for (let i = 0; i < property?.epc?.strapi_json_value.length; i++) {
        if (property?.epc?.strapi_json_value[i].url) {
            propertyEpcImg.push(property?.epc?.strapi_json_value[i].url);
        }
        else if(property?.epc?.strapi_json_value[i].srcUrl) {
            propertyEpcImg.push(property?.epc?.strapi_json_value[i].srcUrl);
        }
    }

    // floorplan lightbox
    let propertyFloorplanImg = [];
    for (let i = 0; i < property?.floorplan?.strapi_json_value.length; i++) {
        if (property?.floorplan?.strapi_json_value[i].url) {
            propertyFloorplanImg.push(property?.floorplan?.strapi_json_value[i].url);
        }
        else if(property?.floorplan?.strapi_json_value[i].srcUrl) {
            propertyFloorplanImg.push(property?.floorplan?.strapi_json_value[i].srcUrl);
        }
    }

    let popularSearchDetailsObject = {
        // department: props.page_url_data.departmentVal,
        searchType: property.search_type,
        // action: property.search_type === "sales" ? "for-sale" : "to-rent",
        area: property.area,
        postcode: property.address.postcode,
        // bedrooms: props.page_url_data.bedVal,
        // propertyType: props.page_url_data.buildingval,
        // minprice: props.page_url_data.minpriceVal,
        // maxprice: props.page_url_data.maxpriceVal,
    }


    useEffect(() => {
        var filtered = _.filter(officeData, function (o) { return o.node.office_crm_id === property.office_mapping });
        setofficeDetailData(filtered)
    }, [officeData])

    return (
        <LayoutTwo footerClass="footer-cta" popularSearchDetailsObject={popularSearchDetailsObject} popularSearch={"residential-detail"}>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                parentlabel={property.search_type === "sales" ? "Buy" : "Rent"}
                parentlink={property.search_type === "sales" ? PageLinks.buy : PageLinks.rent}
                subparentlabel={property.search_type === "sales" ? "Property for sale" : "Property to rent"}
                subparentlink={property.search_type === "sales" ? PageLinks.results_sales : PageLinks.results_lettings}
                title={property.display_address}
            />

            <PropertyDetailBanner
                title={property.display_address}
                images={property?.images}
                processedImages={property?.ggfx_results}
                strapi_id={property?.strapi_id}
                crm_id={property?.crm_id}
                propsImg={propertyDetailImg}
                propertyEpcImg={propertyEpcImg}
                propertyFloorplanImg={propertyFloorplanImg}
                videoTour={property?.video_tour}
                status={property?.status}
            />

            <PropertyDetailDesc
                property={property}
                companyphone={property?.crm_negotiator_id?.mobile_phone ? property?.crm_negotiator_id?.mobile_phone : property?.crm_negotiator_id?.work_phone}
                propImg={propertyDetailImg}
                page_url={page_url}
                setNegoEmail={setNegoEmail}
                isSoldProperty={isSoldProperty}
                negDetails={officedetailData}
            />

            <SimilarProperties
                prop_id={property?.strapi_id}
                tag="property-details"
                showDividerLine
                title={`Similar properties ${property.search_type == "sales" ? "for sale" : "to rent"}`}
            />

            <SellLetHome {...officeDetailGlobal.footer_help_module} />

            <FloatingButton
                tag="property-details"
                phone={property?.crm_negotiator_id?.mobile_phone ? property?.crm_negotiator_id?.mobile_phone : property?.crm_negotiator_id?.work_phone}
                type={property.search_type}
                crm_id={property.crm_id}
                address={property.display_address}
                page_url={page_url}
                propImage={propertyDetailImg && propertyDetailImg.length > 0 ? propertyDetailImg[0] : ''}
                nego_email={negoEmail}
                isSoldProperty={isSoldProperty}
                negDetails={officedetailData}
            />
        </LayoutTwo>
    )
}


export const query = graphql`
  query ($crm_id: String) {
    strapiProperty(crm_id: { eq: $crm_id }) {
      ...PropertyFragment
    }
    site {
        siteMetadata {
            mailVars {
                company_phone
            }
        }
    }
    strapiOfficeDetail {
        footer_help_module {
          ...PlainContentFragment
        }
    }
    allStrapiOffice(filter: {publish: {eq: true}}) {
        edges {
          node {
            title
            email
            phone
            office_crm_id
            image {
                alternativeText
                url
            }
            imagetransforms {
                image_Transforms
            }
            strapi_id
            ggfx_results {
              ...STRAPI_GGFX_RESULTFragment
            }
          }
        }
    }
  }
`

export const Head = (props) => {
    //seo title, h1 and desc prepare, this can send to utilis function later
    let desc_meta_ptype = props.data.strapiProperty?.building?.strapi_json_value?.length > 0 ? props.data.strapiProperty?.building?.strapi_json_value.join(" and ") : "property"

    let cap_ptype = desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1);

    let desc_meta_seachtype = `for sale`
    if (props.data.strapiProperty?.search_type == "lettings") {
        desc_meta_seachtype = `to rent`
    }
    let desc_meta_beds = props.data.strapiProperty?.bedroom
    let desc_meta_address = props.data.strapiProperty?.display_address
    let desc_meta_price = `£` + new Intl.NumberFormat('en-UK').format(props.data.strapiProperty?.price)

    let pagetitle = `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedroom${desc_meta_beds <= 1 ? "" : "s"} in ${desc_meta_address} at ${desc_meta_price}`

    let pagemetadesc = `Know the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedroom${desc_meta_beds <= 1 ? "" : "s"} in ${desc_meta_address} at ${desc_meta_price}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to get assistance in finding the right ${desc_meta_ptype} for you.`

    let pageUrl = process.env.GATSBY_SITE_URL + (props?.location?.pathname).replace(/\/?$/, '/')
    let myimgtransforms = ''
    if (props.data.strapiProperty?.imagetransforms?.images_Transforms) {
        myimgtransforms = JSON.parse(props.data.strapiProperty?.imagetransforms?.images_Transforms);
    }
    let pageImg = sitelogoimage;
    if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
        let mymetaimg = Object.values(myimgtransforms);
        let pageImg_filt = []
        for (const myimgtransform in myimgtransforms) {
            if (typeof myimgtransforms[myimgtransform]['webp'] !== "undefined") {
                pageImg_filt = Object.values(myimgtransforms[myimgtransform]['webp']);
                break;
            }
        }
        if (pageImg_filt.length > 0)
            pageImg = pageImg_filt[0]
    }


    var ldJson = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": props.data.strapiProperty?.slug.replace(/-/g, " "),
        "image": props.data.strapiProperty?.images?.strapi_json_value[0]?.url,
        "description": pagemetadesc,
        "brand": {
            "@type": "Organization",
            "name": process.env.GATSBY_SITE_NAME,
            "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`
        },
        "offers": {
            "@type": "Offer",
            "url": pageUrl,
            "priceCurrency": "GBP",
            "price": props.data.strapiProperty?.price,
            "availability": "https://schema.org/InStock"
        }

    };
    return (
        <Seo title={pagetitle} description={pagemetadesc}>
            <meta name="image" content={pageImg} />
            <meta property="og:image" itemprop="image" content={pageImg} />
            <meta property="og:image:secure_url" content={pageImg} />
            <meta property="og:image:url" content={pageImg} />
            <meta name="og:image" content={pageImg} />
            <meta name="og:image:width" content="400" />
            <meta name="og:image:height" content="300" />
            <meta name="twitter:image" content={pageImg} />
            <meta name="og:url" content={pageUrl} />
            <meta name="twitter:url" content={pageUrl} />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </Seo>
    )
}

export default PropertyDetails